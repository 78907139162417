import styled from 'styled-components';
import Logo from '../../assets/logo_high.png';

export const TopSectionContainer = styled.div`
  position: absolute;
  color: #ffff;
  left: 0;
  top: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(9,0,0,0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 99;
  margin: 0;
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: min(5vw, 50px);
  margin: 0;
`;

export const SubTitle = styled.h2`
  font-size: min(4vw, 40px);
  margin: 0;
`;

export const LogoImg = styled.img.attrs({src: Logo})`
  @media screen and (max-width: 650px) {
    max-width: 310px;
  }
`;

export const Panel = styled.div`
  background-color: rgba(0,0,0,0.6);
  border-radius: 3px;
  width: 650px;
  @media screen and (max-width: 800px) {
    width: 100%;
  }
  height: 260px;
  @media screen and (max-height: 300px ) {
    height: 100%;
  }
  position: absolute;
  z-index: -1;
`;