import React from 'react';
import {TopSectionContainer, Title, SubTitle, LogoImg, Panel} from './top-section.styles';

const TopSection = () => {
  return (
    <TopSectionContainer>
      <Panel />
      <LogoImg alt='CAB. INST. EL.'/>
      <Title> Site under construction</Title>
      <SubTitle>coming soon...</SubTitle>
    </TopSectionContainer>
  )
}

export default TopSection