import React, { useRef } from 'react';
import * as THREE from 'three';

import EarthDayMap from '../../assets/textures/2k_earth_daymap.jpg';
import EarthNormalMap from '../../assets/textures/2k_earth_normal_map.jpg';
import EarthSpecularMap from '../../assets/textures/2k_earth_specular_map.jpg';
import EarthCloudsMap from '../../assets/textures/2k_earth_clouds.jpg';
import { useFrame, useLoader } from '@react-three/fiber';
import { TextureLoader } from 'three';
import { OrbitControls, Stars } from '@react-three/drei';

const Earth = () => {
  const [colorMap, normalMap, specularMap, cloudMap] = useLoader(TextureLoader, [EarthDayMap, EarthNormalMap, EarthSpecularMap, EarthCloudsMap]);
  const earthRef = useRef();
  const cloudRef = useRef();

  useFrame(({clock}) => {
    const elapsedTime = clock.getElapsedTime();
    earthRef.current.rotation.y = elapsedTime / 6;
    cloudRef.current.rotation.y = elapsedTime / 6;
  });

  return (
    <>
      {/* <ambientLight intensity={1} /> */}
      <pointLight 
      color='#f5eccc'
      position={[2,-2,8]}
      intensity={1.2}
      />
      <Stars 
      radius={300}
      depth={60}
      count={20000}
      factor={7}
      saturation={0}
      fade={true}
      />
      <mesh ref={cloudRef} position={[0,0,3]} >
        <sphereGeometry args={[1.005, 32, 32]} />
        <meshPhongMaterial
        map={cloudMap}
        opacity={0.4}
        deptWrite={true}
        transparent={true}
        side={THREE.DoubleSide}
        />
      </mesh>
      <mesh ref={earthRef} position={[0,0,3]} >
        <sphereGeometry args={[1, 32, 32]} />
        <meshStandardMaterial 
        map={colorMap}
        normalMap={normalMap}
        metalness={0.4}
        roughness={0.7}
         />
        {/*<OrbitControls 
        enableZoom={true}
        enablePan={true}
        enableRotate={true}
        zoomSpeed={0.6}
        panSpeed={0.5}
        rotateSpeed={0.4} */}
        
        />
      </mesh>
    </>
  );
};

export default Earth;