import { Canvas } from '@react-three/fiber';
import { Suspense } from 'react';
import './App.css';
import {CanvasContainer} from './App.styles.jsx';
import Earth from './components/earth/earth.component';
import TopSection from './components/topSection/top-section.component';

function App() {
  return (
    <CanvasContainer>
      <TopSection />
      <Canvas>
        <Suspense fallback={null}>
          <Earth />
        </Suspense>
      </Canvas>
    </CanvasContainer>
  );
}

export default App;
